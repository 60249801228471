import React, { useMemo } from 'react'
import * as Yup from 'yup'
import { Drawer } from '@components/Drawer'
import { DrawerContext } from '@hooks/useDrawer'
import { FieldArray, Form, Formik } from 'formik'
import { ordinalNumbers } from '@libs/utils'
import InputField from '@components/form/InputField/InputField'
import { CoverMultiplesForm } from '@models/Bot/CoverMultiplesForm'
import PrimaryButton from '@components/buttons/PrimaryButton'
import { requiredNumberMsg } from '@constant/errorMessage'
import { Bot } from '@models/Bot/Bot'

const validationSchema = ({
  isFirstOrderDouble,
}: {
  isFirstOrderDouble: boolean
}) =>
  Yup.object().shape({
    coverMultiples: Yup.array().of(
      Yup.number()
        .required(requiredNumberMsg)
        .test({
          name: 'min-value',
          test: (value, ctx) => {
            if ((value ?? 0) < 1) {
              return ctx.createError({
                path: ctx.path,
                message: `Value must have minimum value at 1`,
              })
            }
            return true
          },
        })
    ),
  })

const CoverMultiplesDrawer = ({
  drawer,
  onSubmit,
  initialValues,
  bot,
  isFirstOrderDouble,
}: {
  bot?: Bot | null
  drawer: DrawerContext
  onSubmit: (form) => void
  initialValues: CoverMultiplesForm
  isFirstOrderDouble: boolean
}) => {
  const schema = useMemo(() => {
    return validationSchema({
      isFirstOrderDouble:
        bot?.botStrategy.isFirstOrderDouble ?? isFirstOrderDouble,
    })
  }, [bot, isFirstOrderDouble])
  return (
    <>
      <Drawer
        isDrawerOpen={drawer.isDrawerOpen}
        handleClickClose={drawer.handleClickClose}
        header='Cover multiples'
      >
        {drawer.isDrawerOpen && (
          <div className='max-w-[700px] mx-auto'>
            <Formik
              initialValues={initialValues}
              initialTouched={{
                coverMultiples: true,
              }}
              onSubmit={form => {
                onSubmit(form)
                drawer.handleClickClose()
              }}
              validationSchema={schema}
              validateOnMount
            >
              {({ values, errors }) => {
                return (
                  <Form>
                    <FieldArray
                      name='coverMultiples'
                      render={() => (
                        <>
                          {values.coverMultiples?.map((coverMultiple, i) => (
                            <div
                              key={i}
                              className='flex items-center py-[12px]'
                            >
                              <div className='flex-1'>{`${ordinalNumbers(
                                i + 1
                              )} Cover multiple`}</div>
                              <InputField
                                {...(bot && { disabled: i < bot.coverRound })}
                                type='number'
                                name={`coverMultiples.${i}`}
                                className='w-[160px]'
                                showErrorWithoutTouched
                                rightIcon={
                                  <span className='text-sm font-medium'>
                                    share(s)
                                  </span>
                                }
                              />
                            </div>
                          ))}
                        </>
                      )}
                    />
                    <PrimaryButton
                      type='submit'
                      className='w-full mt-[20px] rounded-[18px]'
                    >
                      Confirm
                    </PrimaryButton>
                  </Form>
                )
              }}
            </Formik>
          </div>
        )}
      </Drawer>
    </>
  )
}

export default CoverMultiplesDrawer
